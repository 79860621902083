.gif-page {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.gif-container {
  width: 100%;
  height: 100%;
  /* max-width: 960px;
  max-height: 540px; */
}

.gif_player {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.play_button {
  background-image: linear-gradient(#80dfc4, #520e5b);
  box-shadow: 0 0 0 3px rgba(#5e648e, 0.9);
  color: #ffdf9b;
  cursor: pointer;
  font-family: GILSANUB, Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-weight: bold;
  left: 50%;
  opacity: 1;
  padding: 35px 50px;
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%) scale(1) rotate(0deg);
  transition: transform 0.3s, opacity 0.3s;
}

.play_button:hover {
  background-color: rgba(#5e648e, 0.5);
  box-shadow: 0 0 0 3px rgba(#5e648e, 0.5);
}

.play_button::after {
  content: "Click To Hit";
}

.playing .play_button {
  transform: translate(-50%, -50%) scale(0) rotate(280deg);
  opacity: 0.5;
}
/**/
img {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.4s ease;
}

.next-btn,
.play-btn {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.next-btn {
  right: 5% !important;
  bottom: 5% !important;
  left: unset !important;
  transform: unset !important;
  display: none;
}

/** video*/
.video-element {
  width: 100%;
  height: 100%;
  pointer-events: none !important;
  object-fit: fill;
}
