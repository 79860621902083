.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;
  opacity: 1;
  animation: showOverlay 1s ease-in;
  z-index: 9999;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.orientation-modal {
  width: 90%;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 9999;
  margin: auto;
  transform: translate(50%, -50%);
  background-color: white;
  padding: 75px 20px;
  text-align: center;
  border: 2px solid #9e731a;
  border-radius: 20px;
  animation: showSmooth 2s ease;
}

.orientation-modal .image-container {
  width: 65px;
  height: 65px;
}

.orientation-modal h2 {
  font-family: monospace;
  font-weight: bold;
  letter-spacing: 1.8px;
  line-height: 40px;
  font-size: 1.6rem;
}

.reload-btn {
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 1.4rem;
  font-family: "GILSANUB";
  color: #9e731a;
  border-color: #9e731a;
  border: 1px solid;
}

@keyframes showSmooth {
  from {
    top: -50%;
  }
  to {
    top: 50%;
  }
}

@keyframes showOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
