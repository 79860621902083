.sideBar-title {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: white;
  font-size: 0.55rem;
}

@media only screen and (max-width: 1100px) and (max-height: 700px) {
  .sideBar-title {
    font-size: 0.25rem !important;
  }
}
