.homePage .main-container {
  flex-direction: column;
}

.outerPage .outer-content {
  font-family: Calibri, sans-serif;
  font-weight: bold;
  width: 70%;
  text-align: start;
  color: white;
  font-size: 1.5rem;
  letter-spacing: 2px;
  line-height: 1.7;
}

.outerPage .outer-content span {
  color: #fff;
  font-size: 2.5rem;
}

.qr-content-container {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  align-items: center;
}

.qr-content-container .qr-container {
  width: 200px;
}

.outerPage .btn-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 40px;
}

@media only screen and (max-width: 1100px) {
  .outerPage .btn-container {
    margin: 20px;
  }
  .outerPage .btn-container .main-btn {
    padding: 15px;
    /* height: 75px; */
    font-size: 0.75rem !important;
  }

  .outerPage .outer-content {
    width: 55%;
    font-size: 0.75rem;
    letter-spacing: 1.2px;
    line-height: 1.4;
  }

  .qr-content-container .qr-container {
    width: 100px;
  }

  .outerPage .outer-content span {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .outerPage .outer-content {
    font-size: 0.5rem !important;
  }
}

@media only screen and (max-height: 700px) {
  .outerPage .btn-container {
    margin: 20px;
  }
  .outerPage .btn-container .main-btn {
    padding: 15px;
    /* height: 75px; */
    font-size: 0.75rem !important;
  }

  .outerPage .outer-content {
    width: 55%;
    font-size: 1rem;
    letter-spacing: 1.2px;
    line-height: 1.4;
  }

  .qr-content-container .qr-container {
    width: 100px;
  }

  .outerPage .outer-content span {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 1100px) and (max-height: 700px) {
  .outerPage .main-container {
    justify-content: start;
    margin-top: 20px;
  }
}
