.overlay-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999 !important;
}
.overlay-backdrop .overlay-spinner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 25;
}
.overlay-backdrop .overlay-spinner-wrapper .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 26;
  font-size: 50px;
  color: var(--icons-color);
}
