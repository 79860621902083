.homePage {
  width: 100vw;
  height: 100svh;
  overflow: hidden;
  background: url(../../assets/images/intro.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
}

.homePage .main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homePage .main-content {
  width: 60%;
  text-align: center;
}

.homePage .main-title {
  font-family: GILSANUB;
  text-transform: uppercase;
  color: #ffc44a;
  font-size: 6.5rem;
}

.homePage .home-btn {
  height: 50px;
}
