.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.btn-container .main-btn {
  border: none;
  padding: 20px 30px;
  font-size: 1.5rem;
  color: #ffdf9e;
  border-radius: 1rem;
  letter-spacing: 0.186562rem;
  font-weight: bold;
  font-family: "GILSANUB";
  /* background: url(../../assets/images/Button.png) no-repeat center center; */
  /* background-size: cover; */
  background-image: linear-gradient(#80dfc4, #520e5b);
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .btn-container .main-btn {
    padding: 15px;
  }
}
