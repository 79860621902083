.footer-container {
  position: absolute;
  bottom: 0;
  margin: 20px;
  margin-left: 70px;
  display: flex;
  align-items: end;
  gap: 10px;
}

.footer-container .logo {
  margin-left: -18px;
  width: 200px;
}

.footer-container .footer-text {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.85);
  font-weight: bold;
}

/* @media only screen and (max-width: 1100px) {
  .footer-container .logo {
    margin-left: -13px;
  }
  .footer-container .footer-text {
    font-size: 0.3rem;
  }
} */

@media only screen and (max-width: 1100px) and (max-height: 700px) {
  .footer-container .logo {
    width: 100px !important;
    margin-left: -10px !important;
  }
  .footer-container .footer-text {
    font-size: 0.35rem !important;
  }
}
