.App {
  width: 100vw;
  height: 100svh;
  overflow: hidden;
}

.fade {
  opacity: 0 !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

@media only screen and (max-width: 1100px) {
  .homePage .main-title {
    font-size: 2.5rem !important;
  }

  .btn-container .main-btn {
    font-size: 1rem !important;
  }

  .footer-container {
    margin: 10px 25px !important;
  }

  .footer-container .logo {
    width: 150px !important;
  }

  .sideBar-title {
    translate: 40% !important;
  }
}

@media screen and (max-width: 1100px) and (max-height: 700px) {
  .sideBar-title {
    font-size: 0.3rem;
    left: -70px !important;
  }
}
